<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_hotcountry" @click="$event.stopPropagation()">
        <div class="head">
          <h1>{{ $t('country.real_country') }}</h1>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="rank_list">
          <ul>
            <li v-for="(item, idx) in countries.filter((country, index) => index < 3)" :key="idx" @click="onCheck(item, idx)">
              <span>{{ $t('country.rank') + myPad(idx + 1, 2) }}</span>
              <br>
              <a href="javascript:">
                <CountryFlag :src="item.country_code"/>
                <h2>{{ item.country_name }}</h2>
              </a>
            </li>
          </ul>
        </div>
        <div class="rank_more">
          <a href="javascript:" @click="$emit('show-all-country')">{{ $t('country.more_country') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./RealCountry.scss"></style>

<script>
import CountryFlag from '@/components/CountryFlag';
import { pad } from "../../lib/util";

export default {
  name: `RealCountry`,
  components: {
    CountryFlag
  },
  data() {
    return {
      backButtonRouteGuard: null,
    }
  },
  props: {
    show: { type: Boolean, default: false },
    countries: { type: Object, default: {} },
    isGlobalMode: { type: Boolean, default: false, }
  },
  emits: ['close', 'select-hot-country', 'show-all-country'],
  mounted() {
  },
  watch: {
    show(show) {
      if (show) {
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_hotcountry').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },

    myPad(val, len) {
      return pad(val, len)
    },

    onCheck(item, idx) {
      this.$emit('select-hot-country', idx);
    },

  }
}
</script>

