<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_allcountry" @click="$event.stopPropagation()">
        <div class="head">
          <h1>{{ $t('country.title') }}</h1>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="search">
          <input type="text" v-model="keyword" :placeholder="$t('country.search')">
          <a href="javascript:">
            <img src="../../assets/img/ico_search.png" @click="onSearch">
          </a>
        </div>
        <div class="country_list">
          <div class="country-grid">
            <div v-for="(item, idx) in searchedCountryList" :key="idx"
              :class="['grid-item', { selected: this.country == item.country_code.toLowerCase() }]"
              @click="onCheck(item)">
              <CountryFlag :src="item.country_code" />
              <label>{{ item.country_name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./AllCountry.scss"></style>

<script>
import CountryFlag from '@/components/CountryFlag';
import { getData } from 'country-list';

const allCountryList = getData().map(it => {
  it.country_code = it.code;
  it.country_name = it.name;
  return it;
});

export default {
  name: `AllCountry`,
  components: {
    CountryFlag
  },
  data() {
    return {
      keyword: "",
      backButtonRouteGuard: null,
      searchedCountryList: allCountryList,
    }
  },
  props: {
    show: { type: Boolean, default: false },
    country: { type: String, default: '' },
  },
  emits: ['close', 'select-country'],
  watch: {
    show(show) {
      if (show) {
        this.keyword = ""
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_allcountry').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },

    onClose() {
      this.$emit('close');
    },

    onSearch() {
      if (this.keyword === '') {
        this.searchedCountryList = allCountryList;
      } else {
        this.searchedCountryList = allCountryList.filter(it => it.country_name.toLowerCase().includes(this.keyword));
      }
    },

    onCheck(item) {
      this.$emit('select-country', item);
    },
  }
}
</script>

