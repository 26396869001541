<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('live.title', {nickname: myInfo == null ? '' : myInfo.user_nickname})"></h1>
    </div>
    <div class="smenu">
      <div class="country">
        <a href="javascript:" :class="{now: this.selectCountry == ''}"
           @click="this.selectCountry = ''">
          All Country
        </a>
        <a v-if="myInfo != null" href="javascript:" :class="{now: this.selectCountry == myInfo.user_country_code}"
           @click="this.selectCountry = myInfo.user_country_code">
          My Country
        </a>
        <a href="javascript:" v-for="(item, idx) in trafficCountries.filter((country, index) => index < 2)"
           :key="idx" :class="{now: this.selectCountry == item.country_code}"
           @click="this.selectCountry = item.country_code;">
          <img v-if="item.country_code !== ''" :src="require('../../assets/flag/' + item.country_code + '.png')">{{ item.country_name }}
        </a>
        <a href="javascript:"
           :class="{now: this.selectCountry != '' &&
           !(trafficCountries.filter((country, index) => index < 2).find(it => it.country_code == this.selectCountry))}"
           @click="showAllCountryDialog = true">
          {{ $t('live.more_country') }}
        </a>
      </div>
      <div class="hot" v-if="selectHotCountryInfo != null">
        <span>{{ $t('live.real_time_hot_country') }}</span>
        <a href="javascript:" @click="showRealCountryDialog = true">
          {{ selectHotCountryIdx + 1 }}.
          <img :src="require('../../assets/flag/' + selectHotCountryInfo.country_code + '.png')">
          {{selectHotCountryInfo.country_name}}
        </a>
      </div>
    </div>

    <div class="warp">
      <div class="top_btn">
        <a href="javascript:" :class="[{now: sort == 'ranking'}]" @click="sort = 'ranking'">
          <img src="../../assets/img/ico_rank.png">{{ $t('live.ranking') }}
        </a>
        <a href="javascript:" :class="[{now: sort == 'updateDate'}]" @click="sort = 'updateDate'">
          <img src="../../assets/img/ico_latest.png">{{ $t('live.recent_login') }}
        </a>
        <div class="btn_right">
          <a href="javascript:" :class="[{now: gender == 1}]" @click="gender = 1">
            <img src="../../assets/img/ico_female.png">
          </a>
          <a href="javascript:" :class="[{now: gender == 0}]" @click="gender = 0">
            <img src="../../assets/img/ico_male.png">
          </a>
        </div>
      </div>

      <div class="live_list">
        <ul>
          <li v-for="(user, idx) in liveUsers" :key="idx">
            <a href="javascript:" @click="onProfile(user)">
              <UserImage :gender="user.user_sex" :image="user.user_profile_image"/>
              <h2>
                <img  style="width: 18px; height: 15px; margin-top: -5px;" :src="require('../../assets/flag/' + user.user_country_code + '.png')">
                {{ user.user_nickname }}
                <span :class="[{female: user.user_sex == 1}, {male: user.user_sex == 0}]">
                  {{ (user.user_sex == 1 ? $t('common.female_short') : $t('common.male_short')) + user.user_age }}
                </span>
              </h2>
              <p><img src="../../assets/img/ico_recommend.png">{{ user.user_like_cnt }}</p>
            </a>
            <div v-if="user.user_line_busy > 0" class="ing_back">
              <div class="ing">
                <img src="../../assets/img/ico_ing.png"><br>{{ $t('live.video_chatting') }}
              </div>
            </div>
          </li>
          <li>
            <InfiniteLoading :first-load="false" @infinite="getLiveList"/>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
  </div>

  <AllCountry
    :show="showAllCountryDialog"
    :country="this.selectCountry"
    @close="showAllCountryDialog = false"
    @select-country="changeCountryMode"/>
  <RealCountry
    :show="showRealCountryDialog"
    :countries="hotCountries"
    @close="showRealCountryDialog = false"
    @select-hot-country="selectHotCountry"
    @show-all-country="showAllCountryDialog = true; showRealCountryDialog = false;"/>
</template>

<style scoped src="v3-infinite-loading/lib/style.css"></style>
<style lang="scss" scoped src="./Live.scss"></style>

<script>
import InfiniteLoading from 'v3-infinite-loading';
import { PAGE_SIZE } from "@/lib/constant";
import UserImage from "@/components/UserImage";
import { requestPost, showToast } from "@/lib/util";
import { EVENT_NAME } from "../../lib/constant";
import AllCountry from "../dialog/AllCountry";
import RealCountry from "../dialog/RealCountry";

export default {
  name: 'Live',
  components: {
    InfiniteLoading,
    UserImage,
    AllCountry,
    RealCountry,
  },
  data() {
    let selectCountry = window.localStorage.getItem('live_select_country');
    let myInfo = this.$store.getters.me;
    return {
      myInfo: myInfo,
      gender: myInfo == null ? 1 : (myInfo.user_sex == '0' ? 1 : 0),
      sort: 'updateDate',
      selectCountry: selectCountry == null ? '' : selectCountry,
      selectHotCountryInfo: null,
      selectHotCountryIdx: 0,
      pageIndex: 0,
      loadingEnd: true,
      liveUsers: [],
      hotCountries: [],
      trafficCountries: [],
      showAllCountryDialog: false,
      showRealCountryDialog: false,
    }
  },
  watch: {
    sort() {
      this.initLiveList();
    },
    gender() {
      this.initLiveList();
    },
    selectCountry() {
      window.localStorage.setItem('live_select_country', this.selectCountry);
      this.initLiveList();
    },
  },
  mounted() {
    this.pageIndex = 0;
    this.liveUsers = [];
    this.loadingEnd = false;

    this.emitter.on(EVENT_NAME.REPORT_SUCCESS, this.initLiveList)

    //
    // [2024/01/30 10:23 KSH]iOS에서는 매번 영상화면에 입장시마다 권한요청 팝업이 뜨고 있음. 이것을 방지하기 위해 시작시 현시하도록.
    //
    if (navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia({video: true, audio: true}).then((stream) => {
        window.globalMediaStream = stream;
      });
    }
  },
  beforeUnmount() {
    this.emitter.off(EVENT_NAME.REPORT_SUCCESS, this.initLiveList);
  },
  methods: {
    initLiveList: function () {
      this.pageIndex = 0;
      this.liveUsers = [];
      this.loadingEnd = false;

      this.getLiveList();
    },

    getLiveList: function ($state) {
      if (this.loadingEnd) {
        return;
      }

      let _app = this;
      requestPost(
        this.myInfo == null ? 'live/getLiveList_LiveRecommend_WithoutLogin' : 'live/getLiveList_LiveRecommend',
        {
          uid: this.myInfo == null ? 0 : this.myInfo.uid,
          sex: this.gender,
          sort: this.sort,
          country_code: this.selectCountry,
          offset: this.pageIndex * PAGE_SIZE,
          limit: PAGE_SIZE
        },
        response => {
          _app.liveUsers = _app.liveUsers.concat(response.live_list);
          _app.trafficCountries = _app.moveJpToFirstPosition(response.traffic_countries);
          _app.hotCountries = response.hot_countries;
          _app.pageIndex++;
          _app.loadingEnd = response.live_list.length < PAGE_SIZE;
          _app.selectHotCountryInfo = _app.hotCountries[_app.selectHotCountryIdx];

          if ($state) {
            if (_app.loadingEnd) {
              $state.complete();
            }
            $state.loaded();
          }
        },
        (code, msg) => {
          showToast(msg);
          if ($state) {
            $state.loaded();
          }
        }
      );
    },

    moveJpToFirstPosition: function (countries) {
      let countriesWithoutJp = countries.filter(country => country.country_code != 'jp');
      let jpCountry = countries.find(country => country.country_code == 'jp');
      if (jpCountry == undefined) {
        return countriesWithoutJp;
      } else {
        return [jpCountry, ...countriesWithoutJp];
      }
    },

    selectHotCountry: function (idx) {
      this.showRealCountryDialog = false;
      this.selectHotCountryInfo = this.hotCountries[idx]
      this.selectHotCountryIdx = idx;

      this.selectCountry = this.hotCountries[idx].country_code
    },

    onMessage: function (user) {
      this.$root.showMessageSendDlg(user);
    },
    changeCountryMode: function (country) {
      this.showAllCountryDialog = false;
      this.selectCountry = country.country_code.toLowerCase();
    },
    onProfile: function (user) {
      this.$root.showUserDlg(user);
    },
  }
}
</script>
